import { Box, Typography, Stack, Card } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import Link from '@mui/material/Link';
import { useForm } from 'react-hook-form';
import Page from '#/components/shared/ui/Page';
import Banner from '#/components/pages/Landing/Banner';
import useLocales from '#/hooks/useLocales';
import Image from '#/components/shared/ui/Image';
import { usePreventiveCheckUpContact } from '#/api/userQueries';
import { FormProvider, RHFTextField } from '#/components/shared/hook-form';

export default function PreventiveCheckUp() {
  const { translate } = useLocales();

  return (
    <Page title="Vorsorgecheck">
      <Stack direction="column" spacing={20}>
        <Banner
          intro={String(translate('preventiveCheckUp.mainBanner.intro'))}
          heading={String(translate('preventiveCheckUp.mainBanner.title'))}
          title="vorsorgecheck"
          subHeading={String(
            translate('preventiveCheckUp.mainBanner.subtitle')
          )}
          image="https://storage.gutgeregelt.ch/assets/main-landings/banners/vorsorgecheck.webp"
          buttonLabel={String(
            translate('preventiveCheckUp.mainBanner.buttonLabel')
          )}
          url="#vorsorgecheck"
        />
        <TheChallenges />
        <Box
          sx={{
            px: {
              xs: 2,
              md: 15,
            },
            maxWidth: 1264,
            margin: '0 auto',
            alignSelf: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="h3" component="h2" color="primary.main">
            {String(translate('preventiveCheckUp.liabilityRisk'))}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ marginBottom: 6, textAlign: 'center', maxWidth: 1264 }}
          >
            {String(translate('preventiveCheckUp.liabilityRiskSubtitle'))}
          </Typography>
          <Image
            src="https://storage.gutgeregelt.ch/assets/main-landings/sections/haftungsrisiko.webp"
            alt="Haftungsrisiko"
            sx={{
              width: {
                xs: '320px',
                md: '640px',
              },
            }}
          />
        </Box>
        <Benefits />
        <ContactForm />
      </Stack>
    </Page>
  );
}

const theChallenges = [
  {
    title: 'Erhöhte Lebenserwartung',
    description:
      'Neue Diagnose- und Behandlungsmöglichkeiten könnten die Lebenserwartung weiter steigern. Sind Ihre Vorsorgenehmer darauf vorbereitet?',
  },
  {
    title: 'Inflation',
    description:
      'Langfristige Inflationsentwicklungen können die Altersvorsorge stark beeinflussen. Wie sichern Sie die Kaufkraft Ihrer Kunden?',
  },
  {
    title: 'AHV',
    description:
      'Welche Veränderungen erwarten die AHV und wie wirken sie sich auf Ihre Vorsorgenehmer aus?',
  },
];

const TheChallenges = () => {
  const { translate } = useLocales();
  return (
    <Box
      sx={{
        px: {
          xs: 2,
          md: 15,
        },
        maxWidth: 1264,
        margin: '0 auto',
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="h3" component="h2" color="primary.main">
        {String(translate('preventiveCheckUp.theChallenges.title'))}
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ marginBottom: 6, textAlign: 'center' }}
      >
        {String(translate('preventiveCheckUp.theChallenges.description'))}
      </Typography>
      <Box
        sx={{
          display: {
            xs: 'block',
            md: 'flex',
          },
          gap: 3,
        }}
      >
        {theChallenges.map((challenge, i) => (
          <Card
            key={i}
            sx={{
              paddingY: 3,
              paddingX: 2,
              width: {
                xs: '100%',
                md: '33%',
              },
            }}
          >
            <Typography
              variant="h4"
              component="h3"
              color="primary.darker"
              sx={{ marginBottom: 2 }}
            >
              {challenge.title}
            </Typography>
            <Typography variant="body1">{challenge.description}</Typography>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

const benefits = [
  {
    title: 'Transparenz und Aufklärung',
    description:
      'Informieren Sie Ihre Kunden ab 40 Jahren detailliert über die Auswirkungen der steigenden Lebenserwartung und andere Risiken.',
  },
  {
    title: 'Haftungsrisiko mitigieren',
    description:
      'Durch umfassende Aufklärung und dokumentierte Beratung können Sie das Risiko von Haftungsansprüchen erheblich reduzieren und die Sicherheit für Ihre Institution und den Stiftungsrat erhöhen.',
  },
  {
    title: 'Dokumentation',
    description:
      'Nachvollziehbare Dokumentation aller Informations- und Beratungsschritte schützt Sie vor langfristigen Haftungsrisiken.',
  },
];

const Benefits = () => {
  const { translate } = useLocales();
  return (
    <Box
      sx={{
        px: {
          xs: 2,
          md: 15,
        },
        maxWidth: 1264,
        margin: '0 auto',
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="h3" component="h2" color="primary.main">
        {String(translate('preventiveCheckUp.benefits.title'))}
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ marginBottom: 6 }}
        textAlign="center"
      >
        {String(translate('preventiveCheckUp.benefits.description'))}
      </Typography>
      <Box
        sx={{
          display: {
            xs: 'block',
            md: 'flex',
          },
          flex: 1,
          gap: 3,
        }}
      >
        {benefits.map((benefit, i) => (
          <Card
            key={i}
            sx={{
              paddingY: 3,
              paddingX: 2,
              width: {
                xs: '100%',
                md: '33%',
              },
            }}
          >
            <Typography
              variant="h4"
              component="h3"
              color="primary.darker"
              sx={{ marginBottom: 2 }}
            >
              {benefit.title}
            </Typography>
            <Typography variant="body1">{benefit.description}</Typography>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

const ContactForm = () => {
  const ContactSchema = Yup.object().shape({
    name: Yup.string().required('validations.user.name'),
    email: Yup.string()
      .email('validations.user.invalidEmail')
      .required('validations.user.email'),
    mobile_phone: Yup.string().required(
      'validations.user.mobile_phone.required'
    ),
  });

  const defaultValues = {
    name: '',
    email: '',
    mobile_phone: '',
  };

  const methods = useForm({
    resolver: yupResolver(ContactSchema),
    defaultValues,
  });

  const { handleSubmit, reset } = methods;

  const { translate } = useLocales();

  const { mutateAsync: contact, isLoading } = usePreventiveCheckUpContact();

  const onSubmit = async (data: any) => {
    await contact(data).then(() => {
      reset(defaultValues);
    });
  };

  const ctaText = String(translate('preventiveCheckUp.contactForm.cta'));
  const ctaParts = ctaText.split('info@gutgeregelt.ch');

  return (
    <Box
      id="vorsorgecheck"
      sx={{
        px: {
          xs: 2,
          md: 15,
        },
        maxWidth: 1264,
        margin: '0 auto',
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography
        sx={{ textAlign: 'center' }}
        variant="h3"
        component="h2"
        color="primary.main"
      >
        {String(translate('preventiveCheckUp.contactForm.title'))}
      </Typography>
      <Box
        sx={{
          display: {
            xs: 'block',
            md: 'flex',
          },
          alignItems: 'center',
        }}
      >
        <Card
          sx={{
            padding: 2,
            margin: 2,
            width: {
              xs: '100%',
              md: '50%',
            },
          }}
        >
          <Typography variant="subtitle1">
            {String(translate('preventiveCheckUp.contactForm.description'))}
            <p>
              {ctaParts[0]}
              <Link href="mailto:info@gutgeregelt.ch">info@gutgeregelt.ch</Link>
              {ctaParts[1]}
            </p>
          </Typography>
        </Card>

        <Card
          sx={{
            padding: 2,
            margin: 2,
            width: {
              xs: '100%',
              md: '50%',
            },
          }}
        >
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <RHFTextField
                name="name"
                placeholder={String(translate('global.formLabels.name'))}
              />
              <RHFTextField
                name="email"
                placeholder={String(
                  translate('global.formLabels.emailAddress')
                )}
              />
              <RHFTextField
                name="mobile_phone"
                placeholder={String(translate('global.formLabels.mobilePhone'))}
              />
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isLoading}
              >
                <Typography>
                  {String(translate('global.formLabels.submit'))}
                </Typography>
              </LoadingButton>
            </Stack>
          </FormProvider>
        </Card>
      </Box>
    </Box>
  );
};
